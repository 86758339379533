import React, { Component } from "react";
import { Link } from "react-router-dom";
import Localization from "../localization/localization"



class Footer extends Component {


    constructor() {
        super();
        this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
    }


    render() {
        return (
            <div class="col s12 m12 l8">
                <br />
                <div>
                    <div>
                        <div className="col s12  l9">
                            <Link 
                                to="/faq"
                                style={{
                                    marginTop: "1rem"
                                }}
                                className="font-face-mb linkColor"
                            >
                                {this.locale.soporte_FAQ}
                            </Link>
                            <div className="footermargin">
                                <p className="footermargin">{this.locale.soporte_desc_0} {this.locale.space}
                                <a href="mailto:expos@apptl.info" target="_blank" rel="noopener noreferrer" className="font-face-mb  linkColor" >{this.locale.soporte_desc_descarga_app_1}</a>{this.locale.space}
                                <span className="font-face-mb footermargin"> {this.locale.soporte_desc_1}</span><br></br>
                                <span className="font-face-mb responsabilidad"> {this.locale.responsabilidad}</span><br></br>
                                <span className="font-face-mb footermargin"> {this.locale.visita}</span> <a href="https://expomuebleinternacional.com.mx" target="_blank" rel="noopener noreferrer" className="font-face-mb  linkColor" >https://expomuebleinternacional.com.mx</a>
                                </p>
                            </div>
                            <br />
                        </div>
                        <div className="col s6 l3 center">
                            <a class="btn hoverable buttonSoporteBackgroundColor" href="mailto:expos@apptl.info" ><i class="material-icons right">chat</i>{this.locale.soporte_chat}</a>
                            <div className="paddingButtons"></div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Footer;