import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { registerAttendeeNew, getPreAttendeeByUserId } from "../../actions/attendeeActions";
import materialize from "materialize-css"
import Localization from "../localization/localization"
import StepWizard from "react-step-wizard";

import { PersonalInformation } from "./PersonalInformation"
import { CompanyInformation } from "./CompanyInformation"
//import { MainActivityCompany } from './MainActivityCompany'
import { MainActivityCompany } from './MainActivityOnly'
//import { ProductsInterest } from './ProductsInterest'
import { TypeBusiness } from './TypeBusiness'
import { Target } from './Target'
import { getStates, getCities, getCountryByIsoName } from "../../actions/countriesActions";

//import ReactPixel from 'react-facebook-pixel';

const CompradorNew = () => {

  const auth = useSelector(state => state.auth);

  const [state, updateState] = useState({
    form: {
      nacimiento: "",
      sexo: "",
      nombres: "",
      apellidos: "",
      email: auth.user.email,
      celular: "",
      ciudad: "",
      codigoPostal: "",

      //// Empresa
      nombreEmpresa: "",
      razonSocial: "",
      cargo: "",
      telefono: "",
      whatsapp: "",
      telefonoCodigoPais:"52",
      codigoPostalEmpresa: "",
      pais: "MX",
      estado: "",
      ciudadEmpresa: "",
      colonia: "",
      domicilio: "",
      domicilioNumero: "",
      wwwEmpresa: "",
      redesSocialesUrlEmpresa: "",

      //// Principal Actividad Empresa
      principalActividadVentaPublico: false,
      principalActividadMayoreo: false,
      principalActividadDistribuidor: false,
      principalActividadBrokerIntermediario: false,
      principalActividadConstruccionHospitalidad: false,
      principalActividadFabricante: false,
      principalActividadOtros: "",

      //// Giro de la empresa
      giroMuebleria: false,
      giroTiendaDepartamental: false,
      giroConstruccion: false,
      giroServicios: false,
      giroExportador: false,
      giroOtros: "",
      giroImportador: false,


      //// Mercado al que atiende
      mercadoAtiendeCorporativo: false,
      mercadoAtiendeHospitalidad: false,
      mercadoAtiendeResidencial: false,
      mercadoAtiendeConstruccion: false,
      mercadoAtiendeOtros: "",

      ///// Interes
      interesesAccesorios: false,
      interesesArteSacro: false,
      interesesCentrosEntretenimiento: false,
      interesesCocinas: false,
      interesesColchones: false,
      interesesComedores: false,
      interesesIluminacion: false,
      interesesMecedoras: false,
      interesesMesasCentroAuxiliares: false,
      interesesInfantilesJuveniles: false,
      interesesMuebleOficina: false,
      interesesOtros: "",
      interesesRecamarasLiteras: false,
      interesesLiterasMadera: false,
      interesesServicios: false,
      interesesTerrazaJardin: false,
      interesesTapizados: false,
      interesesTextiles: false,
      interesesTapetes: false,


      publicityYes: "",
      publicityNo: "",
      /////
      //principalActividad: "",
      //productosInteres: "",
      categoria: "COMPRADOR",
      tipoRegistro: "pre",
      counter: 0,

      locale: "es",

      //vip: false,
      //vipProcessed: false,
      //errors: {}
    }
    // demo: true, // uncomment to see more
  });

  const [disableSubmit, setdisableSubmit] = useState(false);
  const locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  const history = useHistory();

  const updateForm = (key, value) => {
    const { form } = state;

    form[key] = value;
    updateState({
      ...state,
      form,
    });

    if (key === "pais") {  
      form["ciudad"] = "";
      updateState({
        ...state,
        form,
      });

      populateStates(value);
      populateCountryPhone(value);

    }

    if (key === "estado") {
      if (form.pais === "MX" || form.pais === "US") {
        populateCities(form.pais, value);
      }
      else {
        form["cities"] = [];
        updateState({
          ...state,
          form,
        });
      }
    }
  };

  const submitForm = () => {

    //e.preventDefault();
    setdisableSubmit(true);
    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";


    const { user } = auth;

    const newAttendee = {
      userId: user.id,
      nacimiento: state.form.nacimiento,
      sexo: state.form.sexo,
      nombres: state.form.nombres,
      apellidos: state.form.apellidos,
      email: state.form.email,
      celular: state.form.celular,
      whatsapp: state.form.whatsapp,
      telefonoCodigoPais: state.form.telefonoCodigoPais,
      ciudad: state.form.ciudad,
      codigoPostal: state.form.codigoPostal,

      //// Empresa
      nombreEmpresa: state.form.nombreEmpresa,
      razonSocial: state.form.razonSocial,
      cargo: state.form.cargo,
      telefono: state.form.telefono,
      codigoPostalEmpresa: state.form.codigoPostalEmpresa,
      pais: state.form.pais,
      estado: state.form.estado,
      ciudadEmpresa: state.form.ciudadEmpresa,
      colonia: state.form.colonia,
      domicilio: state.form.domicilio,
      domicilioNumero: state.form.domicilioNumero,
      wwwEmpresa: state.form.wwwEmpresa,
      redesSocialesUrlEmpresa: state.form.redesSocialesUrlEmpresa,


      // Principal Actividad
      principalActividadVentaPublico: state.form.principalActividadVentaPublico,
      principalActividadMayoreo: state.form.principalActividadMayoreo,
      principalActividadDistribuidor: state.form.principalActividadDistribuidor,
      principalActividadBrokerIntermediario: state.form.principalActividadBrokerIntermediario,
      principalActividadConstruccionHospitalidad: state.form.principalActividadConstruccionHospitalidad,
      principalActividadFabricante: state.form.principalActividadFabricante,
      principalActividadOtros: state.form.principalActividadOtros,

      //// Giro de la empresa
      giroMuebleria: state.form.giroMuebleria,
      giroTiendaDepartamental: state.form.giroTiendaDepartamental,
      giroConstruccion: state.form.giroConstruccion,
      //giroEmpresa: state.form.giroEmpresa,
      //giroHotel: state.form.giroHotel,
      //giroMayorista: state.form.giroMayorista,
      giroServicios: state.form.giroServicios,
      giroExportador: state.form.giroExportador,
      giroImportador: state.form.giroImportador,
      giroOtros: state.form.giroOtros,

      //// Mercado al que atiende
      mercadoAtiendeCorporativo: state.form.mercadoAtiendeCorporativo,
      mercadoAtiendeHospitalidad: state.form.mercadoAtiendeHospitalidad,
      mercadoAtiendeResidencial: state.form.mercadoAtiendeResidencial,
      mercadoAtiendeConstruccion: state.form.mercadoAtiendeConstruccion,
      mercadoAtiendeOtros: state.form.mercadoAtiendeOtros,

      ///// Interes
      interesesAccesorios: state.form.interesesAccesorios,
      interesesArteSacro: state.form.interesesArteSacro,
      interesesCentrosEntretenimiento: state.form.interesesCentrosEntretenimiento,
      interesesCocinas: state.form.interesesCocinas,
      interesesColchones: state.form.interesesColchones,
      interesesComedores: state.form.interesesComedores,
      interesesIluminacion: state.form.interesesIluminacion,
      interesesMecedoras: state.form.interesesMecedoras,
      interesesMesasCentroAuxiliares: state.form.interesesMesasCentroAuxiliares,
      interesesInfantilesJuveniles: state.form.interesesInfantilesJuveniles,
      interesesMuebleOficina: state.form.interesesMuebleOficina,
      interesesOtros: state.form.interesesOtros,
      interesesRecamarasLiteras: state.form.interesesRecamarasLiteras,
      interesesLiterasMadera: state.form.interesesLiterasMadera,
      interesesServicios: state.form.interesesServicios,
      interesesTapizados: state.form.interesesTapizados,
      interesesTerrazaJardin: state.form.interesesTerrazaJardin,
      interesesTextiles: state.form.interesesTextiles,
      interesesTapetes: state.form.interesesTapetes,

      categoria: state.form.categoria,
      tipoRegistro: state.form.tipoRegistro,

      locale: language,
      //vip: state.form.vip,
      //vipProcessed: state.form.vipProcessed
    }

    //this.props.updateAttendee(this.props.match.params.id, existingAttendee, this.props.history);

    registerAttendeeNew(newAttendee)
      .then(res => history.push("/compradorlanding?action=add&id=" + newAttendee.userId))
      .catch(error => {
        setdisableSubmit(false);
        materialize.toast({ html: locale.button_submission_error })
      }
      );
  }

  // Do something on step change
  const onStepChange = (stats) => {
    window.scroll(0, 0);
    materialize.AutoInit();
    materialize.updateTextFields();
  };

  const setInstance = SW => updateState({
    ...state,
    SW,
  });

  useEffect(() => {

    const { user } = auth;

    getPreAttendeeByUserId(user.id)
      .then(response => {
        if (response.data !== null) {
          const { form } = state;

          form.nacimiento = response.data.nacimiento;
          form.sexo = response.data.sexo;
          form.nombres = response.data.nombres;
          form.apellidos = response.data.apellidos;
          form.email = response.data.email;
          form.celular = response.data.celular;
          form.telefono = response.data.telefono;
          form.whatsapp = (response.data.whatsapp !== undefined) ? response.data.whatsapp : "";
          form.telefonoCodigoPais = (response.data.telefonoCodigoPais !== undefined) ? response.data.telefonoCodigoPais : "";
          form.domicilio = response.data.domicilio;
          form.colonia = response.data.colonia;
          form.ciudad = response.data.ciudad;
          form.codigoPostal = response.data.codigoPostal;
          form.estado = response.data.estado;
          form.pais = response.data.pais;
          form.nombreEmpresa = response.data.nombreEmpresa;
          form.razonSocial = response.data.razonSocial;
          form.cargo = response.data.cargo;
          form.emailEmpresa = response.data.emailEmpresa;
          form.wwwEmpresa = response.data.wwwEmpresa
          form.locale = response.data.locale;


          updateState({
            ...state,
            form,
          });
        }
        return response.data;
      })
      .then(res => {
        materialize.AutoInit();
        materialize.updateTextFields();
      })
      .catch(error => console.log(error));

    /*
  materialize.AutoInit();
  materialize.updateTextFields();
  */

    //this.sortChildrenHtmlElementsByParentClass('MotivoAsistenciaParent');
    //this.sortChildrenHtmlElementsByParentClass('ProductosInteresParent');
  }, []);


  const populateCountryPhone = (country) => {

    getCountryByIsoName(country)
      .then(response => {
        const { form } = state;
        form.telefonoCodigoPais = response.data[0].phone_code;
        console.log(response.data[0].phone_code);
        updateState({
          ...state,
          form,
        });
      })
      .catch(err => console.log(err));
  }

  const populateStates = (country) => {
    let states = [];
    getStates(country)
      .then(response => {
        states = response.data[0].states.map((state) => {
          return <option key={state} value={state}>{state}</option>
        });

        const { form } = state;
        form.states = states;
        updateState({
          ...state,
          form,
        });
      })
      .catch(err => console.log(err));
  };

  const populateCities = (country, state_) => {
    let cities = [];
    getCities(country, state_)
      .then(response => {
        console.log(response);
        cities = response.data.map((city) => {
          return <option key={city} value={city}>{city}</option>
        });

        const { form } = state;
        form.cities = cities;
        updateState({
          ...state,
          form,
        });

        materialize.AutoInit();
        materialize.updateTextFields();
      })
      .catch(err => console.log(err));
  };

  return (
    <div>
    <div>
      <div className="col s12 m12 l7 container contentaligned overflowDiv">
          <Link to="/Dashboard" className="btn-flat waves-effect">
            <i className="material-icons left">keyboard_backspace</i> {locale.dashboard_comprador_label_backtohome}
          </Link>
          <StepWizard
            onStepChange={onStepChange}
            //isHashEnabled
            // comment out for default transitions
            instance={setInstance}
          >
            <PersonalInformation update={updateForm} locale={locale} form={state.form} />
            <CompanyInformation update={updateForm} locale={locale} form={state.form} />
            <TypeBusiness update={updateForm} locale={locale} form={state.form} />
            <MainActivityCompany update={updateForm} locale={locale} form={state.form} />
            <Target update={updateForm} locale={locale} form={state.form} submit={submitForm} disableSubmit={disableSubmit} />
            {/*}ProductsInterest update={updateForm} locale={locale} form={state.form} submit={submitForm} /> */}
          </StepWizard>
        </div>
      </div>
    </div>
  );
};


export default (withRouter(CompradorNew));
